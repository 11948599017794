import { Number, Date, Klass } from '#backend/lib/transforms'
import { Agent } from './Agent'

export class Contract {
  id: number
  @Date start_date: Date
  @Date end_date: Date
  memo: string
  @Number territory_id: number
  @Klass(Agent) agent: Agent

  @Date created_at: Date
  @Date updated_at: Date
}
