import { Klass } from '#backend/lib/transforms'
import { Site } from './Site'
import { Agent } from './Agent'
import { Province } from './Province'

export class Territory {
  id!: number
  name!: string

  @Klass(Agent) active_agent?: Agent
  @Klass(Site) site!: Site
  @Klass(Province) province!: Province

  get name_with_phone() {
    if (!this.active_agent) return this.name

    return `${this.name}-${this.active_agent.phone}`
  }
}
