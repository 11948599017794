import { School, SchoolInfo, Exportation } from "#backend/models"
import { type AxiosResponse } from "axios"
import { BaseRequest } from "$commons/lib/BaseRequest"
import { Klass } from '#backend/lib/transforms'
import Pagination from "$commons/lib/Pagination"

export const List = class extends BaseRequest<School[]> {
  method = "GET"
  endpoint = "/api/sites/{site_id}/schools"

  processResponse(response: AxiosResponse) {
    return this.responseToArray(response, School)
  }
}

export const Page = class extends BaseRequest<Pagination<School>> {
  method = "GET"
  endpoint = "/api/sites/{site_id}/schools"

  processResponse(response: AxiosResponse) {
    return this.responseToPage(response, School)
  }
}

export const InfoPage = class extends BaseRequest<Pagination<SchoolInfo>> {
  method = "GET"
  endpoint = "/api/sites/{site_id}/schools"
  graph = "info"

  processResponse(response: AxiosResponse) {
    return this.responseToPage(response, SchoolInfo)
  }
}

export const Get = class extends BaseRequest<School> {
  method = "GET"
  endpoint = "/api/sites/{site_id}/schools/{school_id}"

  processResponse(response: AxiosResponse) {
    return this.responseToObject(response, School)
  }
}

export const InfoGet = class extends BaseRequest<SchoolInfo> {
  method = "GET"
  endpoint = "/api/sites/{site_id}/schools/{school_id}"
  graph = "info"

  processResponse(response: AxiosResponse) {
    return this.responseToObject(response, SchoolInfo)
  }
}

export const Create = class extends BaseRequest<School> {
  method = "POST"
  endpoint = "/api/sites/{site_id}/schools"

  processResponse(response: AxiosResponse) {
    return this.responseToObject(response, School)
  }
}

export const Update = class extends BaseRequest<School> {
  method = "PUT"
  endpoint = "/api/sites/{site_id}/schools/{school_id}"

  processResponse(response: AxiosResponse) {
    return this.responseToObject(response, School)
  }
}

export const Destroy = class extends BaseRequest<School> {
  method = "DELETE"
  endpoint = "/api/sites/{site_id}/schools/{school_id}"

  processResponse(response: AxiosResponse) {
    return this.responseToObject(response, School)
  }
}

export const ExportTeacher = class extends BaseRequest<TeacherExporter> {
  method = "POST"
  endpoint = "/api/sites/{site_id}/schools/{school_id}/export_teachers"

  processResponse(response: AxiosResponse) {
    return this.responseToObject(response, TeacherExporter)
  }
}

export const ExportStudent = class extends BaseRequest<StudentExporter> {
  method = "POST"
  endpoint = "/api/sites/{site_id}/schools/{school_id}/export_students"

  processResponse(response: AxiosResponse) {
    return this.responseToObject(response, StudentExporter)
  }
}

export class TeacherExporter {
  id!: number
  @Klass(Exportation) exportation!: Exportation
}

export class StudentExporter {
  id!: number
  @Klass(Exportation) exportation!: Exportation
}
